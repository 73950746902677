@use "variables" as *;

[class^="icon-"] {
    font-size: 0;
    font-style: normal;
    color: var(--white-color);
}

[class*="icon-"]:before {
    font-family: $font-icon;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: normal;
    speak: never;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-video-unfill:before {
    content: '\e803';
}

/* '' */
.icon-search:before {
    content: '\e813';
}

/* '' */
.icon-reset:before {
    content: '\e817';
}

/* '' */
.icon-left-arow:before {
    content: '\e818';
}

/* '' */
.icon-right-arrow:before {
    content: '\e819';
}

/* '' */
.icon-close:before {
    content: '\e81b';
}

/* '' */
.icon-twitter:before,
.icon-b-twitter:before {
    content: '\e81d';
}

/* '' */
.icon-youtube:before,
.icon-b-youtube:before {
    content: '\e81e';
}

/* '' */
.icon-facebook:before,
.icon-b-facebook:before {
    content: '\e81f';
}

/* '' */
.icon-instagram:before,
.icon-b-instagram:before {
    content: '\e820';
}

/* '' */
.icon-whatsapp:before,
.icon-b-whatsapp:before {
    content: '\e851';
}

/* '' */
.icon-copylink:before,
.icon-b-copylink:before {
    content: '\e850';
}

/* '' */
.icon-news:before {
    content: '\e821';
}

/* '' */
.icon-photo:before {
    content: '\e822';
}

/* '' */
.icon-play-store:before {
    content: '\e823';
}

/* '' */
.icon-polo:before {
    content: '\e824';
}

/* '' */
.icon-pro-kabaddi:before {
    content: '\e825';
}

/* '' */
.icon-video:before {
    content: '\e826';
}

/* '' */
.icon-arrow_down:before {
    content: '\e827';
}

/* '' */
.icon-arrow_l:before {
    content: '\e828';
}

/* '' */
.icon-arrow_r:before {
    content: '\e829';
}

/* '' */
.icon-arrow_up:before {
    content: '\e82a';
}

/* '' */
.icon-boxing:before {
    content: '\e82b';
}

/* '' */
.icon-home:before {
    content: '\e82c';
}

/* '' */
.icon-legends-league:before {
    content: '\e82d';
}

/* '' */
.icon-photo_2:before {
    content: '\e82e';
}

/* '' */
.icon-batting:before { content: '\e86c'; } /* '' */
.icon-bowing:before { content: '\e86d'; } /* '' */
.icon-wicket-keeper:before { content: '\e86e'; } /* '' */