@use "../../config/" as *;

.waf-fixtures{
    @extend %p-t-zero;
    .head{
        &-wrap{
            @extend %w-100;
            @extend %flex-column;
            > .title{
                margin-top: calc(var(--full-space) + 0.5rem);
                @include font(16, null, $font-bold);
                @extend %w-100;
                @extend %flex-n-n;
            }
            .tab{
                &-section{
                    order: 3;
                    padding-top: calc(var(--half-space) + 0.5rem);
                    padding-bottom: calc(var(--half-space) * 2);
                    @extend %w-100;
                    @extend %flex-n-n;
                    .tabs{
                        @extend %w-100;
                    }

                }
                &-name{
                    &:hover{
                        &::before{
                            height: 0.4rem;
                        }
                    }
                }
            }
            .filter-section{
                min-height: 3.5rem;
                order: 2;
                margin-top: calc(var(--full-space) * 2);
                @extend %m-b-zero;
                @extend %w-100;
                .selected-title{
                    .title{
                        text-transform: capitalize;
                    }
                }
            }
        }
        &-middle{
            .title{
                @extend %d-none;
            }
            .venue{
                @extend %d-none;
            }
            .matchinfo{
                @extend %d-none;
            }
        }
    }
    .card{
        &-header{
            position: relative;
            background: var(--dark-grey-color);
            height: 2.2rem;
            // @include bottomShape(null, 0.7rem);
            @extend %p-x-half;
            &::after{
                content: "";
                border-right: var(--full-space) solid var(--light-grey-color);
                border-top: var(--full-space) solid transparent;
                @include position-style(bottom-right);
            }

            .head-wrap{
                @extend %flex-row-sb-c;
            }
            .match-no,.status{
                font: 1rem/1.3rem $font-bold;
                color: var(--secondary-color);
            }
            .status{
                @extend %p-r-half;
            }
            .time{
                font: 1.8rem/2.2rem $font-bold;
                white-space: nowrap;
                @include position(-3.5rem,null,null,0);
            }
        }
        &-item{
            position: relative;
            height: 15rem;
            background: var(--light-grey-color);
            // padding-bottom: calc(var(--full-space) + 0.5rem);

            margin-bottom: calc(5 * var(--full-space));
            // @include bottomShape(null, 0.7rem);
            // @extend %m-b-full;
            &::after{
                content: "";
                height: 0.1rem;
                background-color: rgba(var(--black-color-rgb), 0.2);
                @include position(calc(100% + 2rem), null, null, 0);
                @extend %w-100;
            }
            &:last-of-type{
                @extend %m-b-zero;
                &::after{
                    content: unset;
                }
            }
            &.live{
                .card-header{
                    background-color: var(--accent-color);
                    border-left: 0.3rem solid var(--secondary-color);
                    .status{
                        position: relative;
                        line-height: 1.3rem;
                        @include flex-config(flex, null, center, center);
                        &::before {
                            content: "";
                            width: 0.4rem;
                            height: 0.4rem;
                            background: var(--secondary-color);
                            border-radius: 50%;
                            margin-right: var(--quarter-space);
                            @include position-style("y-center", null, null, null, null, relative);
                            @include translate(0, 50%);
                        }
                    }
                }
                .card-footer{
                    &-bottom{
                        .score-btn{
                            @extend %font-zero;
                            @extend %w-100;
                            @extend %h-100;
                            @extend %position-t-l;
                        }
                    }
                    .venue{
                        @extend %d-none;
                    }
                }
            }
            &.upcoming{
                .team{

                    &-info{
                        flex-direction: row;
                        @extend %m-t-zero;
                    }
                    &-a{
                       .team-name{
                            @extend %m-l-full;
                       }
                    }
                    &-b{
                        .team-name{
                            @extend %m-r-full;
                        }
                        .team-info{
                            flex-direction: row-reverse;
                        }
                    }
                }
                .card-footer{
                    &-bottom{
                        .score-btn{
                            @extend %font-zero;
                            @extend %w-100;
                            @extend %h-100;
                            @extend %position-t-l;
                        }
                    }
                }
            }
            &.recent{
                .card-header{
                    background: var(--primary-color);
                    .match-no,.status{
                        color: var(--white-color);
                    }
                }
                .card-footer{
                    &-bottom{
                        .score-btn{
                            @extend %font-zero;
                            @extend %w-100;
                            @extend %h-100;
                            @extend %position-t-l;
                        }
                    }
                    .venue{
                        @extend %d-none;
                    }
                }
                .team{
                    &-score{
                        @extend %m-b-zero;
                    }
                }
            }
        }
        &-wrap{
            position: relative;
            @extend %h-100;
        }
        &-content{
            position: relative;
            height: calc(100% - 5.2rem);
            @extend %flex-n-c;
            @extend %p-x-full;
        }
        &-footer{
            background: var(--grey-color);
            height: 3rem;
            @extend %p-x-full;
            @extend %flex-sb-c;
            &::before {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                border-right: calc(var(--full-space) + 0.5rem) solid var(--white-color);
                border-top: calc(var(--full-space) + 0.5rem) solid transparent;
              }
            .team-status,.venue{
                font: 1rem/1.3rem $font-medium;
                color: var(--secondary-color);
            }
        }
        &-footer-top{
            @extend %flex-n-c;
        }
        &-footer-bottom{
            @extend %flex-n-c;
        }
        &-highlight{
            @extend %d-none;
        }
    }
    .team{
        width: 50%;
        @extend %flex-n-c;
        &-logo{
            width: 3.7rem;
            background: var(--white-color);
            flex-shrink: 0;
            @extend %ratio-1-1;
            @extend %circle-radius;
        }
        &-name{
            font:1.4rem/1.6rem $font-bold;
            color: var(--secondary-color);
            @extend %uppercase;
            @extend %m-t-quarter;
            &.full-name{
                @extend %d-none;
            }
        }
        &-a{
            @extend %p-r-full;
            .team-data{
                @extend %m-r-full;
            }
            .team-extra{
                @extend %m-l-quarter;
            }
        }
        &-b{
            @extend %p-l-full;
            @include flex-config(flex,row-reverse);
            .team-data{
                @extend %m-l-full;
                @include flex-config(flex, column-reverse, null, flex-end);
                &.super-over{
                    @include flex-config(flex, row, null, flex-end);
                    .team-score{
                        @extend %m-b-zero;
                        @extend %m-l-zero;
                    }
                }
            }
            .team-extra{
                @extend %m-r-quarter;
            }
            .team-score{
                @extend %m-l-half;
                @extend %flex-column-n-fe;
            }
        }
        &-info{
            flex-grow: 1;
            @extend %flex-column-n-c;
        }
        &-data{
            position: relative;
            @include flex-config(flex, column-reverse, null, flex-start);
            // @extend %flex-column;
            &.won{
                .team-score{
                    .score{
                        color: var(--primary-color);
                    }
                }
            }
            &.super-over{
                @include flex-config(flex, row-reverse, null, flex-end);
                .team-score{
                    @extend %m-b-zero;
                    @extend %m-r-zero;
                }
            }
        }
        &-score{
            @extend %flex-column-n-fs;
            @extend %m-b-zero;
            @extend %m-r-half;
            .score{
                font: 1.6rem/1.6rem $font-bold;
                color: var(--secondary-color);
                @extend %p-t-half;
                @extend %m-t-half;
                &::after{
                    content: "";
                    height: 0.1rem;
                    background-color: rgba(var(--secondary-color-rgb), $alpha: 0.25);
                    @extend %w-100;
                }
                &:not(:first-child){
                    font:1.2rem/1.2rem $font-bold;
                }
                &:nth-child(1){
                    @extend %p-t-zero;
                    @extend %m-t-zero;
                    &::after{
                        content: unset;
                    }
                }
                &:nth-child(2){
                    &::after{
                        @include position(null, null, 4.5rem, 0);
                    }
                }
                &:nth-child(3){
                    font: 1rem/1rem $font-bold;
                }
                &:last-child{
                    &::after{
                        @include position(null, null, 1.75rem, 0);
                    }
                }
            }
            &.won{
                .score{
                    color: var(--primary-color);
                }
            }

        }
        &-extra{
            @extend %flex-n-fe;
            .overs{
                font: 1rem/1rem $font-bold;
                color: rgba(var(--secondary-color-rgb),0.5);
            }
        }
        &.versus{
            border: 0.1rem solid var(--dark-grey-color);
            width: 3rem;
            @include translate(-50%, -50%);
            @include position(50%, null, null, 50%);
            @extend %ratio-1-1;
            @extend %circle-radius;
            @extend %flex-column-c-c;
            .versus-text{
                text-transform: uppercase;
                font: 1rem/1.3rem $font-bold;
                color: var(--secondary-color);
            }
        }
    }
    .run-rate{
        @extend %d-none;
    }
    .buy-btn{
        font: 1rem/1.2rem $font-bold;
        color: var(--white-color);
        background: var(--primary-color);
        padding: var(--quarter-space) var(--half-space);
        @include bottomShape(null, 0.7rem);
        // @extend %p-quarter;
    }
    .waf-body{
        margin-top: calc(var(--full-space) * 11);
        .nomatch-section{
            margin-top: calc(var(--full-space-negative) * 2);
        }
    }
}


@media (min-width:$tablet-min-width) {
.waf-fixtures{
    .layout-wrapper{
        max-width: var(--container-max-width);
        margin: 0 auto;
    }
    .head{
        &-wrap{
            > .title{
                margin-top: calc(var(--full-space) + 1rem);
                @include font(20, null, $font-bold);
            }
            .filter-section{
                min-height: 5rem;
                margin-top: calc(var(--full-space) * 3);

            }
            .tab{
                &-section{
                    max-width: 57.7rem;
                    padding-top: calc(var(--full-space) + 0.5rem);
                }
            }
        }
    }
    .card{
        &-item{
            height: 11rem;
            margin-bottom: calc(6 * var(--full-space));
            // @include bottomShape(null, 2rem);
            &::after{
                @include position(calc(100% + 3rem), null, null, 0);
            }
            &.live, &.recent, &.upcoming{
                .card-footer{
                    &::after{
                        content: unset;
                    }
                    &-bottom{
                        .score-btn{
                            font-size: 0;
                            &::after{
                                content: '\e828';
                                font:2rem/1rem $font-icon;
                                color: var(--primary-color);
                                @include position-style(y-center,null,1.2rem);
                            }
                        }
                    }
                }
            }
        }
        &-wrap{
            @include flex-config(flex,null,space-between,center);
        }
        &-header{
            width: 20%;
            height: 100%;
            // @include bottomShape(null, 2rem);
            @include flex-config(flex,null,center,center);
            .head{
                &-wrap{
                height: 100%;
                justify-content: center
            }
            &-right{
                @include flex-config(none);
            }
        }
        .match-no{
            font: 2rem/2.6rem $font-bold;
        }
        .time{
            top: -4rem;
            font: 1.8rem/2.2rem $font-bold;
        }
        }
        &-content{
            width: 60%;
            height: 100%;
        }
        &-footer{
            width: 20%;
            height: 100%;
            padding-inline: var(--full-space);
            // @include bottomShape(null, 2rem);
            @include flex-config(null,column,space-evenly,flex-start);
                        &::after{
                content: '\e828';
                font:2rem/1rem $font-icon;
                color: var(--primary-color);
                @include position-style(y-center,null,1.2rem);
            }
            .team-status,.venue{
                font: 1.4rem/2rem $font-medium;
                padding-right: var(--half-space);
            }

        }
    }
    .team{
        &-info{
            margin-top: 0;
            flex-direction: row;
        }
        &-logo{
            width: 5.5rem;
        }
        &-name{
            max-width: 5rem;
            font: 1.5rem/2.2rem $font-bold;
            margin-inline: var(--full-space);
            &.full-name{
                @include flex-config(flex, column, center, flex-start);
            }
            &.short-name{
                @include flex-config(none);
            }
        }
        &-a{
            .team-data{
                margin-right: calc(var(--full-space) * 1.5);
                @include flex-config(flex, row-reverse, flex-end, center);
                &.super-over{
                    @include flex-config(flex, row-reverse, flex-end, flex-end);
                }
            }
        }
        &-b{
            padding-left: var(--full-space);
            .team-data{
                margin-left: calc(var(--full-space) * 1.5);
                @include flex-config(flex, row, flex-start, center);
                &.super-over{
                    @include flex-config(flex, row, flex-start, flex-end);
                }
            }
            .team-info{
                flex-direction: row-reverse;
            }
            .team-name{
                text-align: right;
                &.full-name{
                    @include flex-config(flex, column, center, flex-end);
                }
            }
        }
        &-score{
            .score{
                font: 2.2rem/2.6rem $font-bold;
            }
        }
        &.versus{
            .versus-text{
                font: 1.2rem/1.6rem $font-bold;
            }
        }
    }
    .buy-btn{
        padding: var(--half-space);
        width: 10rem;
    }
    .waf-body{
        margin-top: calc(var(--full-space) * 13);
        .nomatch-section{
            margin-top: calc(var(--full-space-negative) * 3);
        }
    }
}
}

@media (min-width:$tablet-min-width) and (max-width: $tablet-max-width){
    .waf-fixtures{
        .team{
            &-logo{
                width: 3.7rem;
            }
            &-name{
                font:1.4rem/1.6rem $font-bold;
                margin-top: var(--quarter-space);
                &.full-name{
                    display: none;
                }
                &.short-name{
                    display: block;
                }
            }
            &-a{
                .team-data{
                    margin-right: var(--full-space);
                }
            }
            &-b{
                .team-name{
                    &.full-name{
                        display: none;
                    }
                }
                .team-data{
                    margin-left: var(--full-space);
                }
            }
        }
        .card{
            &-header{
                .time{
                    font: 1.2rem/1.6rem $font-bold;
                    @include position(-3rem,null,null,0);
                }
            }
        }
    }
}