@use "variables" as *;
@use "mixins" as *;
@use "map" as *;
@use "animation" as *;

%font-zero {
  font-size: 0;
}


// absolute start
%position-v-center {
  @include position-style(y-center);
}

%position-h-center {
  @include position-style(x-center);
}

%position-center {
  @include position-style(center);
}

%position-t-l {
  @include position-style(null, 0, null, null, 0);
}

%position-t-r {
  @include position-style(null, 0, 0, null, null);
}

%position-b-l {
  @include position-style(null, null, null, 0, 0);
}

%position-b-r {
  @include position-style(null, null, 0, 0, null);
}

%position-t-r {
  @include position-style(top-right);
}

%position-b-l {
  @include position-style(null, null, null, 0, 0);
}

%position-b-r {
  @include position-style(null, null, 0, 0, null);
}

// absolute end

%gap-full {
  gap: var(--full-space);
  row-gap: var(--full-space);
  column-gap: var(--full-space);
}

%gap-half {
  gap: var(--half-space);
  row-gap: var(--half-space);
  column-gap: var(--half-space);
}

%gap-quarter {
  gap: var(--quarter-space);
  row-gap: var(--quarter-space);
  column-gap: var(--quarter-space);
}

// radius
%full-radius {
  @include border-radius(var(--full-radius));
}

%half-radius {
  @include border-radius(var(--half-radius));
}

%circle-radius {
  @include border-radius(50%);
}

%rounded-radius {
  @include border-radius(50vmax);
}

@each $direction,
$direction-value in $directions {

  @each $space,
  $space-value in $spacing {
    %m-#{$direction}-#{$space} {
      margin#{$direction-value}: #{$space-value};
    }

    %p-#{$direction}-#{$space} {
      padding#{$direction-value}: #{$space-value};
    }
  }
}

//how to use
// @extend %m-t-full
// @extend %p-r-half
// @extend %m-b-full-neg
// @extend %p-l-half-neg
// @extend %m-l-auto
// @extend %m-x-full : margin-inline
// @extend %m-y-full : margin-block

@each $space,
$space-value in $spacing {
  %m-#{$space} {
    margin: #{$space-value};
  }

  %p-#{$space} {
    padding: #{$space-value};
  }
}

//how to use
// @extend %m-full
// @extend %p-half
// @extend %m-full-neg
// @extend %p-half-neg

@for $i from 1 through 10 {
  %h-#{calc($i * 10)} {
    height: calc($i * 10%);
  }

  %w-#{calc($i * 10)} {
    width: calc($i * 10%);
  }
}

// text alignment case

%uppercase {
  text-transform: uppercase;
}

%lowercase {
  text-transform: lowercase;
}

%capitalize {
  text-transform: capitalize;
}

// text alignment

%text-center {
  text-align: center;
}

%text-left {
  text-align: left;
}

%text-right {
  text-align: right;
}

%d-none {
  display: none;
}

@each $justify,
$justify-value in $flex-spacing {

  @each $align,
  $align-value in $flex-spacing {
    %flex#{$justify}#{$align} {
      @include flex-config(flex, null, #{$justify-value}, #{$align-value});
    }

    %flex-column#{$justify}#{$align} {
      @include flex-config(flex, column, #{$justify-value}, #{$align-value});
    }
    %flex-row#{$justify}#{$align} {
      @include flex-config(flex, row, #{$justify-value}, #{$align-value});
    }
  }
}

%flex {
	@include flex-config(flex);
}

%flex-row {
	@include flex-config(flex, row);
}

%flex-column {
	@include flex-config(flex, column);
}


// shortform
// 	sa: space-around,
// 	sb: space-between,
// 	se: space-evenly,
// 	fe: flex-end,
// 	fs: flex-start,
// 	c:center,
// 	s:stretch,
// 	u:unset,
// 	n:null

//how to use
// @extend %flex;
// @extend %flex-column;
// @extend %flex-null-c;
// @extend %flex-sb-c;
// @extend %flex-c-fs;
// @extend %flex-column-fs-c;
// @extend %flex-column-c-sb;

%x-scroll {
  overflow-x: scroll;
}

%x-auto {
  overflow-x: auto;
}

%y-scroll {
  overflow-y: scroll;
}

%y-auto {
  overflow-y: auto;
}

@each $width, $height in $aspect-ratio{
  %ratio-#{$width}-#{$height}{
    aspect-ratio: #{$width}/#{$height};
  }
}
%bg-card {
  position: relative;

  &::before {
    content: '';
    width: 90%;
    height: 0.3rem;
    z-index: var(--z-negative1);
    @include linear-gradient(to left, hsl(var(--color-hsl-accent)/1), hsl(var(--color-hsl-secondary)/1));
    @include position(null, null, -0.5rem, 5%);
  }
}