@use "../../config/" as *;

.waf-player {
    margin-bottom: var(--full-space);
    .category{
        @include font(14, 14, $font-medium);
    }
    .player {
        &-card {
            background: var(--light-grey-color);
        }

        &-thumbnail {
            height: 18rem;
            background: url(/static-assets/images/cssimages/player-bg-mob.png?v=#{image-version}) no-repeat;
            background-size: cover;

            img {
                @extend %h-100;
                object-fit: contain;
            }
        }

        &-name {
            @extend %flex;

            .first-name,
            .last-name {
                font: 2.2rem/2.2rem $font-bold;
                color: var(--secondary-color);
            }

            .last-name {
                @extend %m-l-half;
            }
        }

        &-category {
            @extend %m-t-three-fourth;
        }

        &-detail-list {
            padding: 0 var(--full-space) var(--full-space);
        }

        &-detail-item {
            position: relative;
            @extend %p-y-full;
            @extend %flex-row-sb-c;
            .value {
                font: 1.4rem/1.6rem $font-bold;
                color: var(--secondary-color);
                @extend %w-50;
                @extend %text-right;

                &::after {
                    content: ":";
                    @include position-style(null, null, null, null, 50%);
                    @include translate(-50%, 0);
                }
            }

            .label {}
        }

        &-static-content {
            margin-block: calc(2 * var(--full-space));

            .title {
                font: 1.8rem/2.3rem $font-bold;
                @extend %p-b-half;
            }

            .text,
            p {
                font: 1.4rem/2.4rem $font-medium;
                @extend %m-b-full;
            }

            .highlight {
                font: 1.4rem/2.4rem $font-bold;

            }
        }

        &-stats {
            .data-title {
                @extend %m-y-full;
            }

            .table {
                border: 0.1rem solid rgba(var(--black-color-rgb), 0.1);
                @extend %flex;

                &-wrap{
                    @extend %m-b-full;
                    &:last-of-type{
                        @extend %m-b-zero;
                    }
                }
                &-head,
                &-body {
                    flex-basis: 50%;
                    max-width: 50%;
                }

                &-row {
                    @extend %flex-column-n-c;
                }

                &-data {
                    height: 4rem;
                    background-color: rgba(var(--accent-color-rgb), 0.3);
                    border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.1);
                    @extend %w-100;
                    @extend %flex-c-c;

                    &.year {
                        background: var(--secondary-color);

                        span {
                            color: var(--white-color);

                        }
                    }

                    span {
                        font: 1.2rem/1.2rem $font-medium;
                        color: var(--secondary-color);
                        @extend %uppercase;
                    }
                }

                &-body {
                    .table-data {
                        background: var(--white-color);
                    }

                    .year {
                        background: var(--secondary-color);

                    }
                }

            }

        }

        &-content{
            flex-grow: 1;
        }
    }

    .info-section {
        &1 {
            @extend %m-t-full;
            @extend %flex-c-c;
        }

        &2 {
            margin-top: calc(var(--full-space) + 1rem);
            @extend %p-x-half;
        }
    }

    .name-wrap {
        text-align: center;
    }

    
    .info {
        &-list {
            border-top: 0.1rem solid var(--dark-grey-color);
            border-bottom: 0.1rem solid var(--dark-grey-color);
            @extend %p-y-full;
            @extend %flex;
        }

        &-item {
            @include card-count(3, var(--full-space));
            @extend %flex-column-n-c;

            &:nth-child(2) {
                border-left: 0.1rem solid var(--dark-grey-color);
                border-right: 0.1rem solid var(--dark-grey-color);
            }
        }

        &-label {
            font: 1.3rem/2.2rem $font-medium;
        }

        &-value {
            font: 2.2rem/2.2rem $font-bold;
        }
    }
}

@media (min-width:$tablet-min-width) {
    .waf-player {
        margin-bottom: calc(var(--full-space) + 1rem);
        .layout-wrapper {
            max-width: var(--container-max-width);
            margin: auto;
        }

        .player {
            &-thumbnail {
                z-index: var(--z-index1);
                padding-left: 0;
                height: 18rem;
                background: url(/static-assets/images/cssimages/player-bg-web.png?v=#{image-version}) no-repeat;
                background-size: 100% 100%;
                flex-basis: 21.8rem;
                img {
                    object-fit: contain;
                }
            }

            &-card-wrap {
                flex-wrap: unset;
                @include flex-config(flex, null, space-between);
            }

            &-detail-list {
                padding: 0;
                flex-wrap: wrap;
                margin: 0 var(--half-space-negative);
                @include flex-config(flex);
            }

            &-detail-item {
                padding-block: var(--half-space);
                @include card-count(3, var(--full-space));
            }

            &-detail-item {
                .value {
                    position: relative;
                    text-align: left;
                    &::after {
                        @include position-style(null, 50%, null, null, -2rem);
                        @include translate(0, -50%);
                    }
                }
            }

            &-name {

                .first-name,
                .last-name {
                    font-size: 2.8rem;
                }
            }

        }

        .content-wrap {
            padding-left: var(--full-space);
        }


        .basic-player-info {
            position: relative;
            border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.1);
            @include flex-config(flex, null, null, null);

        }

        .extended-player-info {
            padding-top: calc(var(--half-space) + 0.35rem);
            background-color: hsla(var(--color-hsl-primary)/0.08);
        }

        .info-section{
            &1{
                margin-top: calc(var(--full-space) + 0.5rem);
                flex-basis: 50%;
                max-width: 50%;
                @include flex-config(null, null, flex-start, flex-start);
            }   
            &2{
                margin-top: calc(var(--full-space) + 0.5rem);
                flex-basis: 50%;
                max-width: 50%;
                background: transparent;
            }
        }

        .category {
            padding: 0;
            text-align: left;
        }

        .info-list {
            padding-top: 0;
            padding-bottom: calc(var(--full-space) + 0.5rem);
            border: unset;
        }

        .player-stats {
            .table {
                flex-direction: column;

                &-head {
                    max-width: 100%;
                    flex-basis: 100%;

                    .table-data {
                        height: 4.2rem;
                        background: var(--secondary-color);
                        color: var(--white-color);

                        span {
                            font: 1.1rem/1.2rem $font-medium;
                            color: var(--color-white-default);
                        }
                    }
                }

                &-body {
                    max-width: 100%;
                    flex-basis: 100%;

                    .table-data {
                        background-color: transparent;
                        height: 5rem;

                        &.runs {
                            background-color: rgba(var(--accent-color-rgb), 0.4);

                            span {
                                font-family: $font-bold;
                            }

                        }

                        span {
                            font-family: $font-medium;
                        }
                    }
                }

                &-row {
                    flex-direction: row;
                    background-color: var(--color-white-default);

                    &:last-child {
                        background-color: var(--secondary-color);

                        span {
                            color: var(--white-color);
                        }

                    }

                    &:not(:last-child) {
                        border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.1);

                        span {
                            color: var(--secondary-color);
                        }
                    }
                }

                &-data {
                    flex: 1;
                    flex-shrink: 0;
                    padding: var(--half-space) 0;
                    width: unset;
                    border-bottom: unset;

                    &.year {
                        max-width: 15rem;
                        flex-basis: 15rem;
                    }
                }
            }
        }
    }
}

@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width){
    .waf-player {
        .player {
            &-detail-item {
                padding-block: var(--half-space);
                @include card-count(2, var(--full-space));
            }
            &-detail-list {
                margin: 0;
            }
            &-thumbnail {
                height: auto;
                padding-left: calc(var(--full-space) * 2);
                background-size: 100% 100%;
                flex-basis: 50%;
                img {
                    object-fit: cover;
                }
            }
        }
    }
}