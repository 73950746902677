@use "./config" as *;

.waf-scorestrip {
  background-color: var(--fourth-color);
  padding: calc(2 * var(--full-space)) var(--full-space);
  margin: 0 var(--half-space-negative);

  /* shimmer start */
  &.waf-shimmer {
    .card-item {
      &.live,
      &.recent,
      &.upcoming {
        .status {
          .text {
            height: 2rem;
            width: 6rem;
            font-size: 0;
            @include shimmer();
          }
        }
      }

      &.live {
        .status {
          .text {
            &::before {
              height: 0;
            }
          }
        }
      }

      .time-text,
      .time-type {
        width: 2rem;
        font-size: 0;
        height: 1rem;
        @include shimmer();
      }

      .card-info {
        .label {
          font-size: 0;
          height: 2rem;
          width: 80%;
          @include shimmer();
        }
      }

      .team {
        &-score {
          .score {
            height: 1.5rem;
            width: 2rem;
            font-size: 0;
            @include shimmer();
          }
        }

        &-image {
          @include shimmer();

          img {
            height: 0;
          }
        }

        &-name {
          .text {
            width: 6rem;
            font-size: 0;
            margin-bottom: 0.2rem;
            height: 1.2rem;
            @include shimmer();
          }
        }
      }
    }
  }

  /* shimmer end */
  .title,
  .btn-text,
  .text,
  .number,
  .label {
    color: var(--white-color);
    font-size: 1rem;
  }

  .title {
    font: 2rem/1.2 $font-bold;
    text-transform: uppercase;
  }

  .head-wrap {
    margin-bottom: var(--full-space);
    @include flex-config(flex, null, space-between, null);
  }

  .dropdown-group {
    min-width: 10rem;
  }
  .dropdown-label {
    display: none;
  }

  .waf-select-box {
    .selected-title {
      border-color: rgba(var(--white-color-rgb), 0.8);
      &:after {
        content: "\e827";
        color: rgba(var(--white-color-rgb), 0.8);
        font-size: 0.8rem;
        position: relative;
        top: 0.1rem;
        display: none;
      }
      &[aria-expanded="true"] {
        ~ .select-box-wrap {
          display: none;
        }
      }
    }
    .btn-text {
      color: rgba(var(--white-color-rgb), 0.8);
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }

  .card {
    &-content {
      padding: var(--full-space);
      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        border-right: var(--full-space) solid var(--tertiary-color);
        border-top: var(--full-space) solid transparent;
      }
    }
    &-footer {
      .btn-more {
        width: 100%;
        height: 100%;
        @include position(0, null, null, 0);

        .btn-text {
          border: none;
          font-size: 0;
        }
      }
    }

    &-info {
      @include flex-config(flex, null, space-between, center);
    }

    &-list {
      overflow-x: scroll;
      @include flex-config(flex, null, null, null);
    }

    &-item {
      position: relative;
      flex-shrink: 0;
      background: var(--kabaddi-secondary-color);
      @include card-count(1, var(--full-space));
      margin-left: 0;

      &.live,
      &.recent {
        .time-text {
          @include center(both);
        }
      }

      &.live {
        .card-head::after {
          display: block;
        }

        .status {
          .text {
            color: var(--kabaddi-primary-color);
          }
          &::before {
            content: "";
            width: 0.7rem;
            height: 0.7rem;
            background: var(--kabaddi-primary-color);
            border-radius: 50%;
            margin-right: 0.5rem;
          }
        }
      }

      &.recent {
        .status {
          border-color: var(--recent-color);
        }

        .graph-box {
          .circle-outer {
            stroke: var(--recent-color);
          }
        }
      }

      &.upcoming {
        .status {
          border-color: var(--upcoming-color);
        }

        .match-time {
          width: 12rem;
          background-color: transparent;
          @include flex-config(null, column, center, null);
          @include border-radius(3rem);
        }

        .team-score {
          display: none;
        }

        .team-info {
          width: 100%;
        }
      }
      .status {
        line-height: 1;
        @include flex-config(flex, null, center, center);
      }
      .date-time {
        display: none;
      }
    }

    &-head {
      height: 2.6rem;
      position: relative;
      background-color: var(--kabaddi-accent-color);
      padding: var(--full-space);
      @include flex-config(flex, null, null, center);
      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        border-right: var(--full-space) solid var(--fourth-color);
        border-top: var(--full-space) solid transparent;
      }

      .text,
      .number {
        font: 1.2rem/1.2 $font-bold;
        color: var(--kabaddi-secondary-color);
      }
    }
  }

  .label {
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    @include flex-config(flex, null, null, center);
  }

  .match-time {
    width: 16%;
    flex-basis: 16%;
    position: relative;
    @include flex-config(flex, null, center, center);

    .time-text {
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      color: var(--kabaddi-primary-color);
      width: 100%;
      text-align: center;
      margin-bottom: 0.2rem;
    }

    .time-type {
      font: 0.8rem/1 $font-regular;
      color: rgba(var(--white-color-rgb), 0.8);
    }
    .circle-inner {
      stroke: rgba(var(--white-color-rgb), 0.8);
      stroke-width: 2;
      fill: transparent;
    }
    .circle-outer {
      stroke: var(--kabaddi-primary-color);
      stroke-width: 4;
    }
  }

  .score {
    width: 4rem;
    font: 2.2rem/1.1 $font-bold;
    text-align: center;
    color: rgba(var(--white-color-rgb), 0.8);
  }

  .team {
    width: 42%;
    flex-basis: 42%;
    @include flex-config(flex, null, space-between, null);

    &.lead {
      .score {
        color: var(--kabaddi-primary-color);
      }
    }

    &-info {
      @include flex-config(flex, null, null, center);
    }

    &-wrapper {
      padding: 0.3rem 0;
      @include flex-config(flex, null, space-between, null);
      @include border-radius(2rem);
    }

    &-image {
      width: 4rem;
      height: 4rem;
      background-color: var(--white-color);
      padding: 0.5rem;
      flex-shrink: 0;
      @include border-radius(50%);
    }

    &-score {
      @include flex-config(flex, null, null, center);
    }

    &-detail {
      margin-top: var(--full-space);
    }

    &-a {
      .team-name {
        margin-left: var(--full-space);
      }

      .team-score {
        @include border-radius(3rem 0 0 3rem);
        // padding-right: var(--half-space);
      }

      // .score {
      //     text-align: right;
      // }
    }

    &-b {
      @include flex-config(null, row-reverse, null, null);

      .team-info {
        @include flex-config(null, row-reverse, null, null);
      }

      .team-name {
        margin-right: var(--full-space);
      }

      .team-score {
        @include border-radius(0 3rem 3rem 0);
        // padding-left: var(--half-space);
      }
    }

    &-name {
      font-size: 1.4rem;
      color: var(--white-color);

      .text {
        font: 1.4rem/1.2 $font-bold;
        text-transform: uppercase;
      }
    }
    &.active,&.won {
      .score {
        color: var(--kabaddi-primary-color);
      }
    }
  }

  .accordion-head {
    height: 2.7rem;
    width: 13rem;
    padding: 0;
    @include flex-config(none, null, center, null);
    @include linear-gradient(var(--kabaddi-accent-color), var(--kabaddi-primary-color));
  }
  &.cricket-strip{
    background-color: var(--tertiary-color);
    .dropdown-group{
      @extend %d-none;
    }
    .waf-head{
      .head-tab{
        li{
          a{
            background: transparent;
            color: var(--white-color);
            border-bottom: 0.1rem solid var(--white-color);
            @extend %p-half-neg;
          }
        }
      }
    }
    .card{
      &-head{
        .text,.number{
          font: 1rem/1.2rem $font-bold;
        }
      }
      &-item{
        .date-time{
          position: relative;
          @extend %d-none;
          @extend %m-x-half;
          @extend %p-x-half;
          &::after,&:before{
            content:'' ;
            width: .2rem;
            display: block;
            background: var(--white-color);
            @extend %h-100;
          }
          &::before{
            @extend %position-t-l;
          }
          &::after{
            @extend %position-t-r;
          }
        }
        .meta{
          color: var(--white-color);
        }
        .match-time{
          @extend %p-y-half;
        }
        .status{
          @include flex-config(null,null,end,center);
          @include position(1rem,2rem,null,null);
          @extend %w-100;
          &::before{
            background: var(--secondary-color);
          }
          .text{
            color: var(--secondary-color);
            font: 1rem/1.2rem $font-bold;
          }
        }
        .time-text{
          font: 1rem/1.2rem $font-medium;
          color: var(--white-color);
        }
        &.live{
          .status{
            &::before{
              background-color: var(--live-color);
            }
            .text{
              color: var(--live-color);
            }
          }
        }
        &.upcoming{
          .match-time{
            border: unset;
            @include border-radius(unset);
          }
          .team{
            flex-basis: 4rem;
            width: 4rem;
          }
        }
      }
      &-content{
        height: 13rem;
        // @extend %p-half;
        &::after{
          content: unset;
        }
      }

    }
    .team{
      flex-basis: 50%;
      width: 50%;
      @include flex-config(null,null,unset,center);
      &-wrapper{
        align-items: center;
      }
      &-detail{
        margin-top: 0;
        .match-status{
          color: var(--white-colo);
        }
      }
      &-info{
        width: 4.5rem;
        @extend %flex-column;
      }
      &-name{
        @extend %w-100;
        @extend %flex-row-c-c;
      }
      &-a{
        .team-info{
          @extend %flex-fs-c;
        }
        .team-name{
          @extend %m-l-zero;
          // margin-left: var(--half-space);

        }
        .team-score{
          @include border-radius(unset,unset);
          @extend %m-r-three-fourth;
          .score{
            @extend %text-right;
          }
        }
      }
      &-b{
       @include flex-config(flex,row-reverse, flex-end, null);
        .team-info{
          @extend %flex-column-fe-c;
        }
        .team-name{
          @extend %m-r-zero;
        
        }
        .team-score{
          @include border-radius(unset,unset);
          @extend %m-l-three-fourth;
          @extend %flex-column-sb-fs;
          .score{
            @extend %text-left;
          }
        }
      }
      &-score{
        flex-grow: 1;
        margin: 0 var(--half-space) 0;
        @extend %flex-column-sb-fe;
      }
      .score-status{
        width: 4.5rem;
        color: var(--white-color);
        font: 1.4rem/1.8rem $font-bold;
      }
    }
    .score{
      width: auto;
      font: 1.6rem/1.6rem $font-bold;
    }
    .overs{
      color: rgba(var(--white-color-rgb),0.5);
      font: 1rem/1.2rem $font-medium;
    }
    .label{
      padding: var(--half-space) var(--full-space);
      background-color: rgba(var(--kabaddi-primary-color-rgb),0.3);
      @extend %w-100;
      @extend  %position-b-l;
    }
    .match-time{
      width: 3rem;
      height: 3rem;
      flex-basis: unset;
      flex-shrink: 0;
      border:0.1rem solid var(--white-color);
      @extend %circle-radius;
    }
  }
}

  .waf-scorestrip {
    .card-head {
      background-color: var(--kabaddi-primary-color);
      &:after {
        border-right-color: var(--fourth-color);
      }
    }
    .card-item {
      background-color: var(--fourth-color);
    }
  }

@media screen and (min-width: $tablet-min-width) {
  .waf-scorestrip {
    position: fixed;
    left: 0;
    bottom: -9rem;
    padding: 0;
    z-index: var(--accordion-z);
    width: var(--window-inner-width);
    margin: 0;
    @include transition(500ms bottom ease-in-out);

    &.active {
      bottom: 0;

      .accordion-head .btn-text::after {
        @include rotate(0);
      }
    }

    .head-wrap,
    .accordion-body {
      @include flex-config(flex, null, null, null);
    }

    .match-time {
      .time-text,
      .time-type {
        font-size: 1rem;
      }
    }

    .accordion-body {
      position: relative;
    }

    .score-strip {
      &-slider {
        position: relative;
        padding: 0 4rem;
      }
    }

    .swiper-button {
      width: 4rem;
      height: 100%;
      border: none;
      background-color: var(--fourth-color);
      z-index: var(--match-btn);
      cursor: pointer;
      @include border-radius(0);
      @include flex-config(flex, null, center, center);

      &-disabled {
        &::after {
          color: rgba(var(--white-color-rgb), 0.4);
        }
      }

      &-prev {
        @include position(0, null, null, 0);

        &::after {
          content: "\e829";
        }
      }

      &-next {
        @include position(0, 0, null, null);

        &::after {
          content: "\e828";
        }
      }

      &-prev,
      &-next {
        &::after {
          font-family: $font-icon;
          font-size: 1.4rem;
          color: rgba(var(--white-color-rgb), 0.8);
        }

        &.swiper-button-disabled {
          &::after {
            color: rgba(var(--white-color-rgb), 0.4);
          }
        }
      }
    }

    .head-wrap {
      width: 100%;
      margin: 0;

      .title {
        display: none;
      }
    }

    .waf {
      &-head {
        width: 10%;
        flex-basis: 10%;
        flex-shrink: 0;
        padding-left: var(--full-space);
        margin: 0;
        @include flex-config(flex, null, null, center);
      }

      &-body {
        width: 90%;
        flex-basis: 90%;
        flex-shrink: 0;
      }
    }

    .card {
      &-head {
        width: 15%;
        flex-basis: 15%;
        height: 100%;
        flex-shrink: 0;
        &:after {
          border-right-color: var(--fourth-color);
        }
      }

      &-list {
        overflow-x: unset;
      }

      &-content {
        width: 85%;
        flex-basis: 85%;
        height: 9rem;
        @include flex-config(flex, column, center, null);
        &:after {
          display: none;
        }
      }

      &-item {
        background-color: var(--fourth-color);
        @include flex-config(flex, null, null, null);
        @include card-count(3, 0rem);
        flex-basis: unset;
      }

      &-info {
        margin: 0;
      }

      &-number {
        width: 100%;
        height: 100%;
        @include flex-config(flex, column, center, center);

        .text {
          font-size: 1rem;
        }
        .number {
          font-size: 3.2rem;
        }
        .text,
        .number {
          color: var(--kabaddi-secondary-color);
        }
      }
    }

    .dropdown {
      &-label {
        // display: block;
        font: 0.9rem/1 $font-regular;
        color: var(--light-gray-color);
        margin-bottom: var(--half-space);
      }

      &-group,
      &-wrap {
        width: 100%;
        .select-box-wrap {
          bottom: 4rem;
          top: auto;
        }
      }
    }

    .accordion-head {
      @include flex-config(flex, null, null, center);
      @include position(-2.7rem, null, null, 0);

      .accordion-btn {
        position: relative;
        height: 100%;
        width: 100%;
      }

      .btn-text {
        font: 1.2rem/1 $font-bold;
        color: var(--kabaddi-secondary-color);
        text-transform: capitalize;
        width: 100%;
        height: 100%;
        @include flex-config(flex, null, center, center);

        &::after {
          content: "";
          background: url(/static-assets/images/cssimages/shape-icon.png?v=#{$image-version}) no-repeat;
          background-size: 100% 100%;
          width: 5.8rem;
          height: 2.8rem;
          transform: rotate(180deg);
          @include transition(500ms ease-in);
        }
      }
    }
    .team-detail {
      margin-top: 1rem;
    }
    &.cricket-strip{
      background-color: var(--fourth-color);
      .dropdown-group{
        display: block;
      }
      .waf-head{
        .head-tab{
          @include flex-config(none);
        }
      }
      .waf-body{
        flex-basis: 90%;
        width: 90%;
      }
      .card{
        &-head{
          width: 15%;
          flex-basis: 15%;
          // background-color: var(--accent-color);
          .text,.number{
            color: var(--secondary-color);
          }
          .number{
            font: 3.2rem/4rem $font-bold;
          }
        }
        &-content{
          height: auto;
        }
        &-item{
          height: 9rem;
          .date-time{
            @include flex-config(none);
          }
          .status{
            position: unset;
            width: auto;
            &::before{
              background-color: var(--white-color);
            }
            .text{
              color: var(--white-color);
            }
          }
          .time-text{
            color: var(--white-color);
            @include flex-config(flex,null,center,center);
          }
          &.upcoming{
            .team{
              flex-basis: 50%;
              width: 50%;
            }
          }
        }
      }
      .team{
        flex-basis: calc(50% - 4.5rem);
        width: calc(50% - 4.5rem);
        @include flex-config(null,null,unset);
        &-wrapper{
          @include flex-config(null,row);
        }
        &-info{
          width: 100%;
          @include flex-config(null,row,null,null);
        }
        &-a{
          .team-name{
            margin: 0 0 0 var(--half-space);

          }
          .team-info{
            @include flex-config(null,null,center,center);
          }
          .team-score{
            margin-right: var(--full-space);
            @include flex-config(flex, column, center, flex-end);
          }
        }
        &-b{
          @include flex-config(null,row-reverse);
          .team-info{
            @include flex-config(null,row-reverse,center,center);
          }
          .team-name{
            margin: 0 var(--half-space) 0 0;
          }
          .team-score{
            margin-left: var(--full-space);
            @include flex-config(flex, column, center, flex-start);
          }
        }
        &-score{
          margin-inline: var(--quarter-space);
          @include flex-config(null,column,center,center);
        }
        &-detail{
          margin-top: var(--three-fourth-space);
        }
        .score-status{
          width: 4.5rem;
          color: var(--white-color);
          font: 1.2rem/1.6rem $font-bold;
        }
      }
      .label{
        position: relative;
        background-color: unset;
        padding: 0;
      }
      .score{
        width: 4.5rem;
        font: 1.4rem/1.8rem $font-bold;
      }
      .overs{
        font: 1.2rem/1.4 $font-regular;
        color: rgba(var(--white-color-rgb),0.6);
      }
      .waf-select-box{
        .selected-title{
          height: auto;
          padding: var(--half-space);
          cursor: default;
        }
      }

    }
  }
}
